import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { GlobalStateProvider } from "./components/GlobalStateContext";
import { AppProvider } from "./components/AppContext";
import { Provider } from "react-redux";
import store from "./redux/store/store"; // Import your Redux store

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>  {/* Add Redux Provider here */}
        <GlobalStateProvider>
          <AppProvider>
            <App />
          </AppProvider>
        </GlobalStateProvider>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>
);
