import { Box, Container, Toolbar } from "@mui/material";
import React from "react";
import Sidebar from "../../SideBar";
import BreadCrumb from "../../BreadCrumb";

export default function CustomerManagement() {
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container>
          <BreadCrumb />
        </Container>
      </Box>
    </Box>
  );
}
