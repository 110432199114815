import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Box,
  Menu,
  MenuItem,
  Collapse,
  useMediaQuery,
  useTheme,
  styled,
  Divider,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Portrait from "@mui/icons-material/Portrait";
import BuildIcon from '@mui/icons-material/Build';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarState, toggleSidebar } from "../redux/actions/sidebarActions";
import { AccountBox, AccountTree, Assignment, ContactEmergency, ContactSupport, Group, Settings } from "@mui/icons-material";

// Constants for drawer width
const drawerWidth = 240;

// Styled components for the sidebar
const SidebarIcon = styled(ListItemIcon)(({ theme, isActive, sidebarOpen }) => ({
  color: isActive ? "Highlight" : "#1f5b8b",
  justifyContent: "center",
  transition: "all 0.3s ease",
  fontSize: sidebarOpen ? 24 : 30, // Larger icons when sidebar is collapsed
}));

const SidebarText = styled(ListItemText)(({ sidebarOpen }) => ({
  display: sidebarOpen ? "inline" : "none", // Hide text when sidebar is collapsed
  transition: "opacity 0.3s",
}));

const SidebarItem = styled(ListItem)(({ theme, sidebarOpen }) => ({
  padding: sidebarOpen ? theme.spacing(1, 2) : theme.spacing(1),
  display: "flex", // Always display items to show icons even when collapsed
  justifyContent: sidebarOpen ? "flex-start" : "center",
  minHeight: 48,
  transition: "padding 0.3s, background-color 0.3s",
  borderRadius: sidebarOpen ? theme.shape.borderRadius : "10%", // Circular effect when collapsed
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ActiveListItem = styled(ListItem)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.action.selected}, ${theme.palette.primary.main})`,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    background: `linear-gradient(90deg, ${theme.palette.action.hover}, ${theme.palette.primary.dark})`,
  },
}));

const sections = [
  {
    items: [
      { text: "Dashboard", icon: <HomeIcon />, path: "home" },
      { text: "Reports", icon: <AssessmentIcon />, path: "reports" },
      { text: "My Resumes", icon: <DescriptionIcon />, path: "myresumes" },
      { text: "My Profile", icon: <Portrait />, path: "myprofile" },
    ],
  },
  {
    label: "Account",
    items: [
      { text: "Settings", icon: <SettingsIcon />, path: "settings" },
    ],
  },
];

const moreSettings = [
  { text: "Customer", icon: <AccountBox />, path: "customer" },
  { text: "User", icon: <Group />, path: "user" },
  { text: "Assignment", icon: <Assignment />, path: "assignment" },
  { text: "Global Settings", icon: <Settings />, path: "globalSettings" },
];

const Sidebar = () => {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state) => state.user.sidebarOpen); // Accessing sidebar state from Redux
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState("User");
  const [role, setRole] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const username = localStorage.getItem("username");
    const userData = localStorage.getItem("user");
    const getrole = JSON.parse(userData);
    setRole(getrole.role);

    if (username) {
      setLoggedInUser(username);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const savedSidebarState = JSON.parse(localStorage.getItem("sidebarOpen"));
      if (window.innerWidth <= 600) {
        dispatch(setSidebarState(false));
      } else if (savedSidebarState !== null) {
        dispatch(setSidebarState(savedSidebarState));
      } else {
        dispatch(setSidebarState(true));
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  const handleSidebarToggle = () => {
    const newState = !sidebarOpen;
    dispatch(toggleSidebar(newState));
    localStorage.setItem("sidebarOpen", JSON.stringify(newState)); // Persist state in local storage
  };

  const handleNavigation = (path) => {
    if (path === "Logout") {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("user");
      navigate("/"); // Navigate to home route after logout
    } else {
      navigate(`/${path}`);
    }
  };

  const isActive = (path) => location.pathname === `/${path}`;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen);
  };

  const renderSidebarItems = (items) => (
    items.filter(item => {
      if (item.text === "Reports") {
        return role === "manager" || role === "account_manager";
      } else if (item.text === "My Resumes") {
        return role === "recruiter_processing" || role === "lead_processing" || role === "admin";
      }
      return true; // Show all other items
    }).map((item) => (
      <SidebarItem
        key={item.text}
        button
        onClick={() => {
          item.text === "Settings" ? handleSettingsToggle() : handleNavigation(item.path);
        }}
        selected={isActive(item.path)}
      >
        <SidebarIcon isActive={isActive(item.path)} sidebarOpen={sidebarOpen}>
          {item.icon}
        </SidebarIcon>
        <SidebarText sidebarOpen={sidebarOpen}>
          <Typography variant="body2">{item.text}</Typography>
        </SidebarText>
        {item.text === "Settings" && sidebarOpen && (
          settingsOpen ? <ExpandLess /> : <ExpandMore />
        )}
      </SidebarItem>
    ))
  );

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: sidebarOpen ? "flex-start" : "center",
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {/* Optional: Add content or logo here */}
        </Toolbar>
        {sections.map((section) => (
  <List key={section.label} >
    {renderSidebarItems(section.items)}

    {section.label === "Account" && (
      <Collapse in={settingsOpen && sidebarOpen}>
        <List sx={{ pl: sidebarOpen ? theme.spacing(2) : 0, mb: 2 }}>
          {moreSettings.map((item) => (
            <SidebarItem
              key={item.text}
              disablePadding
              button
              onClick={() => handleNavigation(item.path)}
              sx={{ paddingLeft: theme.spacing(4) }}
            >
              <SidebarIcon 
                isActive={isActive(item.path)} 
                sidebarOpen={sidebarOpen}
              >
                {item.icon}
              </SidebarIcon>

              {sidebarOpen && (
                <Typography 
                  variant="body2" 
                  sx={{ ml: 2 }}
                >
                  {item.text}
                </Typography>
              )}
            </SidebarItem>
          ))}
        </List>
      </Collapse>
    )}
  </List>
))}

      </Box>
      <Divider />
      {sidebarOpen && 
      
      <Box sx={{ pr: 2, textAlign: 'center' }}>
        <img
          src="./jinnhire_updated.png" // Replace with your image URL
          alt="Thinking Person"
          style={{ width: '50%',height:"25vh" }}
        />
      </Box>
      
      }<SidebarItem  onClick={() => handleNavigation('contact')}
    >
      <SidebarIcon sidebarOpen={sidebarOpen}>
        <ContactSupport />
      </SidebarIcon>
      <SidebarText sidebarOpen={sidebarOpen}>
        <Typography variant="body2">Contact Jinn</Typography>
      </SidebarText>
    </SidebarItem>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "#1f5b8b",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%", // Ensures top bar spans the full width
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleSidebarToggle}
            edge="start"
            sx={{
              marginRight: theme.spacing(2),
            }}
          >
            {sidebarOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="./jinne_head.png" // Replace with your logo URL
              alt="Logo"
              style={{ height: "40px", marginRight: theme.spacing(1) }}
            />
            <Typography variant="h6" noWrap component="div">
              Jinn Hire
            </Typography>
          </Box>
        </Toolbar>
        <Toolbar>
          <IconButton color="inherit" sx={{ mr: 2 }}>
            <NotificationsIcon />
          </IconButton>
          <IconButton
            color="inherit"
            edge="end"
            onClick={handleMenuOpen}
            sx={{
              ...(anchorEl && {
                backgroundColor: theme.palette.action.selected,
              }),
            }}
          >
            <AccountCircle />
            <Typography
              variant="body2"
              color="text.primary"
              style={{ color: "inherit" }}
              sx={{ ml: 1 }}
            >
              {loggedInUser.split("@")[0]}
            </Typography>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                mt: 2, // Adjust this value as needed to position the menu below the AppBar
                boxShadow: 'none', // Remove shadow
                borderRadius: 1,
                minWidth: 150,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
              },
            }}
          >
            <MenuItem onClick={() => handleNavigation("Logout")}>
              <LogoutIcon sx={{ marginRight: '8px' }} /> Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: sidebarOpen ? drawerWidth : theme.spacing(9), // Sidebar width based on its open state
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sidebarOpen ? drawerWidth : theme.spacing(9),
            boxSizing: "border-box",
            background: `linear-gradient(180deg, ${theme.palette.background.default}, ${theme.palette.background.paper})`,
          },
          transition: "width 0.3s ease",
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
