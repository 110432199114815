import { SET_USER } from "../actions/userActions";
import { TOGGLE_SIDEBAR, SET_SIDEBAR_STATE } from "../actions/sidebarActions"; // Combine imports

const initialState = {
  role: "",
  license_code: "",
  isAuthenticated: false,
  sidebarOpen: JSON.parse(localStorage.getItem('sidebarOpen')) ?? true, // Initialize from localStorage
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case TOGGLE_SIDEBAR:
      const toggledSidebarOpen = !state.sidebarOpen;
      localStorage.setItem('sidebarOpen', JSON.stringify(toggledSidebarOpen)); // Update localStorage
      return {
        ...state,
        sidebarOpen: toggledSidebarOpen,
      };
    case SET_SIDEBAR_STATE:
      localStorage.setItem('sidebarOpen', JSON.stringify(action.payload)); // Update localStorage
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;