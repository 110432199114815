import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Divider,
  IconButton,
  useTheme,
  styled,
  Toolbar,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidebar from "../SideBar";
import BreadCrumb from "../BreadCrumb";
import TopBar from "../TopBar";

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing(3),
  backgroundColor: "#f4f6f9",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[6],
  padding: theme.spacing(4),
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(3),
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  border: `4px solid ${theme.palette.background.paper}`,
  [theme.breakpoints.up("md")]: {
    marginRight: theme.spacing(3),
    marginBottom: 0,
  },
}));

const ProfileInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

const InfoText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: "1rem",
  color: theme.palette.text.secondary,
}));

const MyProfile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    setUserData(data);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <TopBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container className="home-wrapper">
          <BreadCrumb />
          <ProfileContainer>
            <ProfileHeader>
              <ProfileAvatar
                src={
                  userData?.profilePicture ||
                  "/path-to-default-profile-picture.jpg"
                }
                alt="Profile Picture"
              />
              <ProfileInfo>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ fontWeight: "bold", mb: 1 }}
                >
                  {userData?.username.split("@")[0] || "N/A"}
                </Typography>
                <InfoText variant="h6">
                  Email: {userData?.email || "N/A"}
                </InfoText>
                <InfoText variant="h6">
                  Role: {userData?.role || "N/A"}
                </InfoText>
                <InfoText variant="h6">
                  Phone: {userData?.phone_number || "N/A"}
                </InfoText>
              </ProfileInfo>
            </ProfileHeader>
          </ProfileContainer>
        </Container>
      </Box>
    </Box>
  );
};

export default MyProfile;
