export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SET_SIDEBAR_STATE = "SET_SIDEBAR_STATE";

export const toggleSidebar = () => ({
  type: 'TOGGLE_SIDEBAR',
});

export const setSidebarState = (isOpen) => ({
  type: 'SET_SIDEBAR_STATE',
  payload: isOpen,
});
