import React from 'react';
import { Modal, Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Button, TextField, Divider } from '@mui/material';
import { blue, red, green, grey } from '@mui/material/colors';

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500, 
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "16px",
};


const buttonContainerStyle = {
  marginTop: "24px",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  gap: "16px",
};

const textFieldStyle = {
  backgroundColor: grey[50], 
  borderRadius: "8px", 
  "& .MuiOutlinedInput-root": {
    borderColor: grey[300],
    borderRadius: "8px", 
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: grey[300],
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: blue[700],
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: blue[900],
  },
};

const CandidateCommentsModal = ({
  commentsModalOpen,
  handleCloseCommentsModal,
  handleCommentChange,
  handleInputChange,
  handleCommentSubmit,
  selectedComment,
  commentInput,
  predefinedComments
}) => {
  return (
    <Modal
      open={commentsModalOpen}
      aria-labelledby="modal-comments-title"
      aria-describedby="modal-comments-description"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-comments-title"
          variant="h6"
          component="h2"
          color={blue[700]}
        >
          Provide Your Comments
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ width: '100%' }}>
          <FormControl component="fieldset">
            <RadioGroup
              value={selectedComment}
              onChange={handleCommentChange}
            >
              {predefinedComments.map((comment, index) => (
                <FormControlLabel
                  key={index}
                  value={comment}
                  control={
                    <Radio
                      sx={{
                        color: blue[700],
                        "&.Mui-checked": { color: blue[900] },
                      }}
                    />
                  }
                  label={comment}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: grey[800],
                    },
                    mb: 1,
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        {selectedComment === "Other (Please specify below)" && (
          <Box sx={{ mt: 2, width: '85%' }}>
            <TextField
              multiline
              rows={3} 
              variant="outlined"
              fullWidth
              value={commentInput}
              onChange={handleInputChange}
              placeholder="Enter your comments here"
              sx={textFieldStyle}
            />
          </Box>
        )}
        <Box sx={buttonContainerStyle}>
          <Button
            onClick={handleCloseCommentsModal}
            variant="contained"
            sx={{
              backgroundColor: red[700],
              color: "white",
              "&:hover": { backgroundColor: red[800] },
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleCommentSubmit}
            variant="contained"
            sx={{
              backgroundColor: green[700],
              color: "white",
              "&:hover": { backgroundColor: green[800] },
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CandidateCommentsModal;
