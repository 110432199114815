import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import NoLicense from "./pages/NoLicense"; // Import the NoLicense component

export default function PrivateRoute() {
  const { isAuthenticated = false, license_code = "" } = useSelector((state) => state.user || {});
  const location = useLocation();

  // Define the valid license codes for each section or component
  const validLicenseCodes = {
    home: ["jinn_hire_chat"],
    profiles: ["jinnhire_match"],
    files: ["jinnhire_scout"],
  };
  const auth = localStorage.getItem("user");
  const currentPath = location.pathname.split("/")[1];

  // Check if the user is authenticated
  // if (!isAuthenticated) {
  //   return <Navigate to="/noauth" />;
  // }

  // Allow access to specific routes without checking license code
  // const unrestrictedPaths = ["settings", "myprofile", "myresumes", "reports","files","home"];
  // if (unrestrictedPaths.includes(currentPath)) {
  //   return <Outlet />;
  // }

  // Check if the user has a valid license code for the current path
  // if (!validLicenseCodes[currentPath]?.includes(license_code)) {
  //   return <NoLicense />;
  // }

  // If authenticated and has a valid license, render the Outlet
  return auth ? <Outlet /> : <Navigate to="/noauth" />;
}
