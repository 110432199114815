import React, { useState } from 'react';
import { Paper, Typography, Divider, Modal, Button, Box } from '@mui/material';

const ResumeComponent = ({ fileLink }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setZoomLevel(1); // Reset zoom level on close
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 2)); // Max zoom level of 2
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Min zoom level of 0.5
  };

  const getFileExtension = (url) => {
    if (typeof url !== 'string') {
      return null;
    }
    const parts = url.split('.');
    if (parts.length === 1) {
      return null;
    }
    return parts.pop().toLowerCase();
  };

  const renderDocument = () => {
    const fileExtension = getFileExtension(fileLink);
    const defaultWidth = '100%';  // Adjusted for modal
    const defaultHeight = '100%'; // Adjusted for modal
  
    switch (fileExtension) {
      case 'pdf':
        return (
          <object
            data={fileLink}
            type="application/pdf"
            style={{
              width: defaultWidth,
              height: defaultHeight,
              border: 'none', // Remove border if any
          overflow: 'hidden', // Hide overflow to prevent scrollbar
            }}
          >
            <p>PDF viewer is not supported. Please download the PDF to view it: <a href={fileLink} target="_blank" rel="noopener noreferrer">Download PDF</a>.</p>
          </object>
        );
      case 'doc':
      case 'docx':
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileLink)}`}
            style={{
              width: defaultWidth,
              height: defaultHeight,
              border: 'none', // Remove border
          overflow: 'hidden', // Hide overflow to prevent scrollbar
            }}
            frameBorder="0"
          >
            <p>Document viewer is not supported. Please download the document to view it: <a href={fileLink} target="_blank" rel="noopener noreferrer">Download Document</a>.</p>
          </iframe>
        );
      default:
        return <div>Unsupported file format</div>;
    }
  };
  

  return (
    <div>
      <Paper
        sx={{
          p: 2,
          border: "1px solid #ddd",
          borderRadius: 2,
          boxShadow: 2,
          minWidth: "35vw",
          overflow: 'hidden',
          position: 'relative',
          mb: 2,
          height: "60vh", // Fixed height on the initial page
        //   overflowY: 'auto',
        }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 2, fontWeight: "bold", textAlign: 'center', position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1, display: 'flex', justifyContent: 'space-between' }}
        >
          Resume
          <Button 
            variant="contained" 
            onClick={handleOpenModal}
            sx={{ backgroundColor: '#0d619f', color: '#fff', padding: '6px 12px', fontSize: '0.8rem' }}
          >
            Enlarge
          </Button>
        </Typography>
        <Divider sx={{ height: 3, backgroundColor: "#0d619f" }} />
        <Box sx={{ height: "calc(55vh - 64px)", mt:1}}>
          {renderDocument()}
        </Box>
      </Paper>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '90vw', // Responsive width
            height: '90vh', // Responsive height
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            overflow: 'hidden', // Hide overflow to prevent scrollbar in modal
            p: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent:"end",
              mb: 2,
            }}
          >
                      <Button 
            onClick={handleCloseModal} 
            sx={{ position: 'absolute', top: 10, right: 10, color: '#fff', backgroundColor: '#f44336','&:hover': { backgroundColor: '#f44336' } }}
          >
            Close
          </Button>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: 'calc(100% - 60px)', // Adjust height to accommodate buttons and padding
              overflow: 'hidden', // Hide overflow to prevent scrolling inside modal
            }}
          >
            {renderDocument()}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default ResumeComponent;
