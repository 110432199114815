import React, { useState } from 'react';
import { Modal, Box, Typography, Divider, List, ListItem, ListItemText, Paper, Button, IconButton } from '@mui/material';
import { blue, grey, green, red, common } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';

// Styles for the modal
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  height: "70%",
  bgcolor: common.white, // Updated to a neutral background color
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: 'hidden',
};

// Styles for the expandable question box
const questionBoxStyle = {
  width: '100%',
  p: 1,
  mb: 2,
  borderRadius: 2,
  bgcolor: blue[50],
  border: `1px solid ${blue[200]}`,
  boxShadow: `0px 4px 6px ${grey[300]}`,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};

// Styles for the chat container
const chatContainerStyle = {
  width: '100%',
  maxHeight: '60vh',
  overflowY: 'auto',
  mb: 2,
};

// Styles for the chat bubbles
const chatBubbleStyle = {
  p: 1.5,
  borderRadius: 2,
  mb: 1,
  maxWidth: '80%',
  display: 'inline-block',
};

// Styles for user and candidate chat bubbles
const userBubbleStyle = {
  ...chatBubbleStyle,
  bgcolor: "#96c99a",
  border: `1px solid ${green[200]}`,
};

const candidateBubbleStyle = {
  ...chatBubbleStyle,
  bgcolor: grey[200],
  border: `1px solid ${grey[400]}`,
};

// Styles for the close button
const closeButtonStyle = {
  position: 'absolute',
  top: 10,
  right: 10,
  bgcolor: red[900],
  color: 'white',
  '&:hover': {
    bgcolor: red[600],
  },
};

const CandidateModal = ({ open, onClose, candidateData }) => {
  const [expanded, setExpanded] = useState(false);

  if (!candidateData) return null;

  const { current_question, questions_list } = candidateData;
  const questionsList = JSON.parse(questions_list || '{}');

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" sx={{ mb: 2 , color:"#0952a3"}}>
          Candidate Conversation
        </Typography>
        <Paper elevation={3} sx={questionBoxStyle} onClick={() => setExpanded(!expanded)}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Current Question
          </Typography>
          <IconButton onClick={() => setExpanded(!expanded)} size="small">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Paper>
        {expanded && (
          <Paper elevation={1} sx={{ width: '100%', p: 2, mb: 2, borderRadius: 2, bgcolor: blue[50], border: `1px solid ${blue[200]}` }}>
            <Typography variant="body2">{current_question}</Typography>
          </Paper>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Conversation:
        </Typography>
        <Box sx={chatContainerStyle}>
          <List>
            {Object.entries(questionsList).map(([question, answer], index) => (
              <ListItem key={index} sx={{ p: 0 }}>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        ...userBubbleStyle,
                        ml: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography variant="body2">{question}</Typography>
                    </Box>
                  }
                  secondary={
                    <Box
                      sx={{
                        ...candidateBubbleStyle,
                        mr: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography variant="body2">{answer || 'No answer provided'}</Typography>
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Button
          onClick={onClose}
          variant="contained"
          sx={closeButtonStyle}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default CandidateModal;
