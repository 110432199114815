import React from 'react';
import { Modal, Box, Typography, FormControlLabel, Checkbox, Button, TextField, Divider } from '@mui/material';
import { green, red, grey } from '@mui/material/colors';

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500, 
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "20px",
};

const checkboxContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  overflowY: "auto", 
  maxHeight: "300px", 
  marginBottom: "16px", 
};

const buttonContainerStyle = {
  marginTop: "auto",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const textFieldStyle = {
  backgroundColor: grey[50], 
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    borderColor: grey[300],
    borderRadius: "8px", 
    padding: "8px", 
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: grey[300],
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: grey[500],
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: grey[700],
  },
};

const CandidateEvaluationModal = ({
  modalOpen,
  handleCloseModal,
  handleCheckboxChange,
  handleInputChange,
  handleSubmit,
  selectedOptions,
  commentInput,
  modalContent
}) => {
  return (
    <Modal
      open={modalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Candidate Evaluation
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box sx={checkboxContainerStyle}>
          {modalContent.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  sx={option.style}
                  checked={selectedOptions.includes(option.text)}
                  onChange={(e) => handleCheckboxChange(option.text, e.target.checked)}
                />
              }
              label={option.text}
            />
          ))}
        </Box>
        {selectedOptions.includes("Other (Please specify below)") && (
          <Box sx={{ mt: 2, width: '100%' }}>
            <TextField
              multiline
              rows={4} 
              variant="outlined"
              fullWidth
              value={commentInput}
              onChange={handleInputChange}
              placeholder="Enter your comments here"
              sx={textFieldStyle}
            />
          </Box>
        )}
        <Box sx={buttonContainerStyle}>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color="error"
            sx={{ mt: 3 }}
          >
            Close
          </Button>
          <Button
            onClick={() => handleSubmit(selectedOptions, commentInput)}
            variant="contained"
            color="success"
            sx={{ mt: 3 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CandidateEvaluationModal;
